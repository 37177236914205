export const config = {
  api_base: 'https://prod.diningtek.com/api/v1',
  api_root : 'https://prod.diningtek.com',
  logo_img_root : '/img/pinocchios-logo.png',
  banner_img_root : '/img/pinocchios-banner.png',
  resid : '',
  key_value : 'pinocchiosniwot',
  secret_value : 'pinocchiosniwot',
  iframe_root : 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3051.7896691874225!2d-105.17279108511578!3d40.10240218262093!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876bf089bb17d383%3A0x341930604648e57b!2s300%202nd%20Ave%2C%20Niwot%2C%20CO%2080503%2C%20USA!5e0!3m2!1sen!2sin!4v1583403798854!5m2!1sen!2sin',
  facebook_link:'#',
  RestaurantName:"Pinocchios Niwot Order Online"
};
